
	//find three closest locations
		var pos_options = {
		  enableHighAccuracy: false,
		  timeout: 5000,
		  maximumAge: 50000
		};

		var g_number_displayed = 5;
		var g_output_format = 'html';
		var g_output_div = '.emergency_results';
		var g_output_result = '';

		function run_location_finder(num_displayed, output_format, output_div) {
			g_number_displayed = num_displayed;
			g_output_format = output_format;
			g_output_div = output_div;
			navigator.geolocation.getCurrentPosition(pos_success, pos_error, pos_options);
		}

		function sort_number(a,b) {
			return parseFloat(a.distance) - parseFloat(b.distance);
		}

		function pos_find_closest(current_pos){
			location_data_sort;
			var distance = [];
			$.each( location_data, function( key, value ) {
  				distance.push({
					'office_id' : parseInt(key),
					'office_name' : value.office_name,
					'address' : value.address,
					'city' : value.city,
					'state' : value.state,
					'zip' : value.zip,
					'phone' : value.phone,
					'fax' : value.fax,
					'phone_to' : value.phone_to,
					'phone_ti' : value.phone_ti,
					'longitude' : value.longitude,
					'latitude' : value.latitude,
					//'distance' : to_miles(calc_distance(25.7617, -80.1918, value.latitude, value.longitude))
					'distance' : to_miles(calc_distance(current_pos.latitude, current_pos.longitude, value.latitude, value.longitude))
				});
			});
			distance.sort(sort_number);
			location_data_sort = distance;
			return distance;
		}

		function pos_output_results(current_pos) {
			var sorted_distance = pos_find_closest(current_pos);
			if(g_output_format == 'html') {
				format_html_results(sorted_distance, g_output_div);
			}
			else if(g_output_format == 'var') {
				g_output_result = sorted_distance[0].office_id;
				set_office();

			}
		}

		function format_html_results(sorted_distance, g_output_div) {

			var address_output = '';
			var phone_output = '';
			$.each(sorted_distance, function( key, value ){
				if(key >= g_number_displayed){
					return false;
				}
				//address_output += '<p class="strong">Our Closest Office</p><p>' + value.office_name + '</p><p class="office_address">' + value.address + ', ' + value.city + ', ' + value.state + '</p><p class="office_distance smaller">' + Math.round(value.distance) + ' Miles From You</p>';
				phone_output += '<a href="tel:+1-' + value.phone + '" class="tel" data-tel="+1-' + value.phone + '" onclick="javascript:trackEvent(\'Mobile\', \'Click to Call\', \'+1-' + value.phone + '\');">' + value.phone + '</a>'
			});

			//$(g_output_div).html(address_output);
			$(g_output_div).html(phone_output);

		}

		function pos_success(pos) {
			var current_pos = pos.coords;
			pos_output_results(current_pos);
		}

		function set_office() {
			$(g_output_div).val(g_output_result);
			$(g_output_div).find('option[value="' + g_output_result + '"]').text($(g_output_div).find('option[value="' + g_output_result + '"]').text() + ' (Closest Location)');
			$(g_output_div).selectmenu('refresh');
			$(g_output_div).change();
		}

		function pos_error(err) {
			$('.emergency_results').css('background','none');
			if(err){
				$('.zip_intro_text').html(err);
			}
			$('.zip_code_form').show();
		}

		function to_miles(km) {
			return km * 0.621371;
		}

		function calc_distance(lat1, lon1, lat2, lon2) {
			var R = 6371; // Radius of the earth in km
			var dLat = (lat2 - lat1) * Math.PI / 180;
			var dLon = (lon2 - lon1) * Math.PI / 180;
			var a =
			     0.5 - Math.cos(dLat)/2 +
			     Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
			     (1 - Math.cos(dLon))/2;
			return R * 2 * Math.asin(Math.sqrt(a));
		}
		function is_json(str) {
    		try {
        		JSON.parse(str);
    		} catch (e) {
    			console.log('error');
        		return false;
    		}
    		return true;
		}

		function pos_ajax(zip) {
			$.ajax({
				url: 'js/ajax/zip_lookup.php?zip=' + zip,
				data: {
				  format: 'json'
				},
				error: function(data) {
				  pos_error(data);
				},
				success: function(data) {
			   		if(is_json(data)){
				   		$('.zip_code_form').hide();
				   		site_data = JSON.parse(data);
						var pos = {
			   				coords: {
			   					latitude : site_data.lat,
			   					longitude: site_data.lng
			   				}
				   		};
				   		pos_success(pos);
				   	}
				   	else {
				   		pos_error(data);
				   	}
			   },
			   type: 'GET'
			});
		}
